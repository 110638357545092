import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Button, AppBar, Toolbar, Container } from '@material-ui/core';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
import AccountPopover from '../../components/AccountPopover';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import Searchbar from './Searchbar';
//guards
import useAuth from '../../hooks/useAuth';
//apis
import { categoryApi } from '../../apis';
//utils
import { fRouter } from '../../utils/formatRouter';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const PATH_OPTIONS = [
  {
    title: 'Home',
    path: PATH_PAGE.root,
    icon: <Icon icon={homeFill} width={22} height={22} />
  },
  {
    title: 'Browse',
    path: PATH_PAGE.browse + fRouter(),
    icon: <Icon icon={homeFill} width={22} height={22} />
  }
];
// ----------------------------------------------------------------------

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const { isAuthenticated } = useAuth();
  const [navConfig, setNavConfig] = useState([
    ...PATH_OPTIONS,
    {
      title: 'Genre',
      icon: <Icon icon={roundGrain} width={22} height={22} />,
      path: '/genre',
      children: []
    }
  ]);

  useEffect(() => {
    const getCategories = async () => {
      await categoryApi.getCategories().then((response) => {
        let nav = [
          ...PATH_OPTIONS,
          {
            title: 'Genre',
            icon: <Icon icon={roundGrain} width={22} height={22} />,
            path: '/genre',
            children: response.data
          }
        ];
        setNavConfig(nav);
      });
    };
    getCategories();
  }, []);

  return (
    <AppBar color={isHome ? 'transparent' : 'default'} sx={{ boxShadow: 0 }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <div style={{width: '30px'}}></div>
          <Searchbar />
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
          {isAuthenticated && <AccountPopover />}
          {!isAuthenticated && (
            <Button variant="contained" href="/auth/login">
              Login
            </Button>
          )}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
